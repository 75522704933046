import { navigate } from "gatsby"
import React, { useEffect, useState, useRef } from "react"
import Countries from "../../../../static/countries.json"
import H2 from "../../core/H2"
import loadable from "@loadable/component"
const ReCAPTCHA = loadable(() => import("react-google-recaptcha"))


const NewContactForm = () => {
    const selectRef = useRef(null);
    const [data, setData] = useState([]);
    const [isShowDropdown, setIsShowDropdown] = useState(false)
    const [pageNumber, setPageNumber] = useState(1)
    const [nda, setNda] = useState(false)
    const [countryCode, setCountryCode] = useState("")
    const [message, setMessage] = useState(undefined)
    const [status, setStatus] = useState(undefined)
    const [current, setCurrent] = useState("")
    const [disabled, setDisabled] = useState(false)
    const [isRecaptcha, setIsRecaptcha] = useState(false)
    const captchaRef = useRef(null);
    const [recaptchaComponent, setRecaptchaComponent] = useState(null);
    // const [optionsCountry, setOptionsCountry] = useState([])

    // const [defaultValue, setDefaultValue] = useState("")

    useEffect(() => {
        const timeout = setTimeout(async () => {
          // Dynamically import the package after 9 seconds
          const { default: ReCAPTCHA } = await import('react-google-recaptcha');
          setRecaptchaComponent(<ReCAPTCHA
            sitekey="6Ld19jMnAAAAAH9Y24lduuFjNMmBZEWaTwLwxLQE"
            onChange={onChangeRecaptcha}
            onErrored={onErroredRecaptcha}
            onExpired={onExpiredRecaptcha}
            ref={captchaRef}
            className="recaptch" />);
        }, 9000); // Delay of 9 seconds
    
        return () => clearTimeout(timeout);
      }, []);

    const onChangeRecaptcha = () => {
        setIsRecaptcha(true);
    }
    const onErroredRecaptcha = () => {
        setIsRecaptcha(false);
    }
    const onExpiredRecaptcha = () => {
        setIsRecaptcha(false);
    }

    function paginate(array, page_size, page_number) {
        const start_index = (page_number - 1) * page_size;
        const end_index = start_index + page_size;
        setPageNumber(pageNumber + 1)
        return array?.slice(start_index, end_index);
    }
    useEffect(() => {
        if(!(pageNumber-1)){
            const start_index = (pageNumber - 1) * 10;
            const end_index = start_index + 10;
            setData(Countries?.slice(start_index, end_index))
        }
    }, [pageNumber])

    function handleScroll(event) {
        const element = event.target;
        // if (element.scrollHeight - element.scrollTop === element.clientHeight) {
            let newData = paginate(Countries, 10, pageNumber);
            setData(prevData => [...prevData, ...newData]);
        // }
    }
    const onClickSelectBox = () => {
        setIsShowDropdown(!isShowDropdown)
    }
    const handleKeyDownSelect = (event) => {
        if (event.key === "Enter" || event.key === " ") {
            onClickSelectBox();
        }
    };
    const handleKeyDownSelectOption = (event, item) => {
        if (event.key === "Enter" || event.key === " ") {
            onClickCountryOption(item)
        }
    };
    // const onBlurSelectBox = () => {
    //     setIsShowDropdown(false)
    // }
    const onClickCountryOption = (e) => {
        setCountryCode(`${e.flag} ${e?.code} (${e?.dial_code}) `)
        setIsShowDropdown(false)
    }
    useEffect(() => {
        setCurrent(window.location.pathname)
    }, [])
    // const changeHandler = e => {
    //     setCountryCode(e.target.value)
    // }
    // const handleOption = () => {
    //     const test = Countries
    //     // setOptionsCountry(test)
    // }
    const onSubmit = e => {
        e.preventDefault()
        setDisabled(true)
        const form = e.target
        const data = new FormData(form)
        const GCL_ID =
            JSON.parse(localStorage.getItem("gclid")) !== null &&
                JSON.parse(localStorage.getItem("gclid")).value !== null
                ? JSON.parse(localStorage.getItem("gclid")).value
                : undefined
        data.set("countryCode", countryCode)
        data.set("gclid", GCL_ID)
        data.set("nda", nda ? "1" : "0")
        data.set("companyName", "")
        data.set("formType", "Footer Contact Form")
        data.set("pageURL", `https://webmobtech.com` + current)
        data.set("recaptcha_token", captchaRef.current.getValue())

        // eslint-disable-next-line no-unused-vars
        const response = fetch(
            // "http://127.0.0.1:8080/api/web/contactus/contact_submit",
            "https://api.webmobtech.com/wmtblog/api/web/contactus/contact_submit",
            {
                method: "POST",
                body: data,
            }
        )
            .then(response => {
                return response.json()
            })
            .then(res => {
                if (res.meta.status === "ok") {
                    document.getElementById("contact-form").reset()
                    setNda(false)
                    navigate("/thank-you/")
                }
                setDisabled(false)
                setMessage(res.meta.message)
                setStatus(res.meta.status)
            })
    }
    useEffect(() => {
        fetch('https://ipapi.co/json/')
            .then(function (response) {
                return response.json();
            })
            .then(function (payload) {
                //    const cus=Countries.find((val)=>val.code===payload?.country_code)
                Countries.sort((x, y) => { return x.code === payload?.country_code ? -1 : y.code === payload?.country_code ? 1 : 0; });
                // setOptionsCountry(Countries)
                setCountryCode(`${Countries[0].flag} ${Countries[0].code} (${Countries[0].dial_code}) `)
            });
    }, [])
    const Notification = ({ status, message }) => {
        if (!status) return null
        return (
            status && (
                <div
                    className={` rounded-md ${status === "ok" ? "bg-green-50" : "bg-red-50"
                        } p-4`}
                >
                    <div className="flex">
                        <div className="ml-3">
                            <p
                                className={`text-sm leading-5 font-medium ${status === "ok" ? "text-green-800" : "text-red-800"
                                    }`}
                            >
                                {message}
                            </p>
                        </div>
                        <div className="ml-auto pl-3">
                            <div className="-mx-1.5 -my-1.5">
                                <button
                                    onClick={() => setStatus(false)}
                                    className={`inline-flex rounded-md p-1.5 ${status === "ok"
                                        ? "text-green-500 focus:bg-green-100 hover:bg-green-100"
                                        : "text-red-500 focus:bg-red-100 hover:bg-red-100"
                                        }  focus:outline-none transition ease-in-out duration-150`}
                                >
                                    <svg
                                        className="h-5 w-5"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        )
    }

    return (
        <div className="common-index">
            <div className=" rounded-md   overflow-hidden ">
                <div className="relative">
                    <form
                        id="contact-form"
                        method="POST"
                        className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-5 lg:grid-cols-2 lg:gap-6"
                        onSubmit={e => onSubmit(e)}
                    >
                        {status && (
                            <div className="sm:col-span-2">
                                <Notification status={status} message={message} />
                            </div>
                        )}
                        <div className="lg:col-span-2 md:col-span-2 col-span-2 mt-1 relative">
                            <H2 title="Let's Work Together!" className="pb-6 text-center md:text-left " textColor={"#FFFFFF"} />
                        </div>
                        <div className=" col-span-2 mt-1 relative">
                            <input
                                id="fullName"
                                name="fullName"
                                placeholder="Full Name *"
                                required
                                className={input_classes}
                            />
                        </div>
                        {/* <div className="lg:col-span-1 md:col-span-1 col-span-2 mt-1 relative ">
                            <input
                                // type="tel"
                                id="contactNo"
                                name="contactNo"
                                required
                                placeholder="Phone *"
                                className={input_classes}
                            />
                        </div> */}
                        <div className="col-span-2 relative ">
                            <input
                                id="emailId"
                                name="emailId"
                                type="email"
                                placeholder=" Email *"
                                required
                                className={input_classes}
                            />
                        </div>
                        <div className="col-span-2 flex flex-wrap">

                            <div className="md:w-1/3 sm:w-1/2 w-full lg:pr-0 md:pr-2 mt-3 relative placeholder_white">
                                <div className='rounded-md bg-transparent form-input py-3 px-4 block w-full text-white border border-solid  border-blue-300 transition ease-in-out duration-150 text-sm cursor-pointer focus:border-white' ref={selectRef} onClick={onClickSelectBox} role='button' tabIndex="0" onKeyDown={handleKeyDownSelect}>
                                    {countryCode ? countryCode : 'Country Code *'}
                                </div>

                                {
                                    isShowDropdown ?
                                        <div style={{ overflowY: 'scroll', height: '150px' }} className='absolute z-10 bg-white rounded-md select-height form-select py-3 px-4 block w-full h-full border border-solid text-blue-400 border-blue-300 transition ease-in-out duration-150 text-sm cursor-pointer' onScroll={handleScroll}>
                                            {data.map((item, index) => (
                                                <span role='button' tabIndex="0" onKeyDown={(e) => handleKeyDownSelectOption(e, item)} key={index} className='cursor-pointer flex flex-col py-1' onClick={() => onClickCountryOption(item)}>{item.flag}&nbsp;{item.code}&nbsp;({item.dial_code})</span>
                                            ))}
                                        </div>
                                        : null
                                }
                            </div>

                            {/* <div className="md:w-1/3 sm:w-1/2 w-full lg:pr-0 md:pr-2 mt-1 relative placeholder_white">
                                <select
                                    aria-label="Country"
                                    name="countryCode"
                                    id="countryCode"
                                    value={countryCode}
                                    onFocus={handleOption}
                                    onChange={changeHandler}
                                    required
                                    className="rounded-md bg-transparent select-height form-select py-3 mt-2 px-4 block w-full h-full border border-solid text-white border-white border-opacity-50 focus:border-white transition ease-in-out duration-150"
                                >
                                    {optionsCountry && optionsCountry.map(e => (
                                        <option
                                            className="custom_option text-shark-500"
                                            key={Math.random()}
                                        >
                                            {e.code}&nbsp;({e.dial_code})&nbsp;{e.flag}
                                        </option>
                                    ))}
                                </select>
                            </div> */}
                            <div className="md:w-2/3 sm:w-1/2 w-full lg:pl-6 md:pl-2 mt-5 md:mt-1 relative ">
                                {/* <input
                                    id="skypeOrWhatsapp"
                                    name="skypeOrWhatsapp"
                                    placeholder=" Skype ID/WhatsApp No."
                                    className={input_classes}
                                /> */}
                                <input
                                    // type="tel"
                                    id="contactNo"
                                    name="contactNo"
                                    required
                                    placeholder="Phone *"
                                    className={input_classes}
                                />
                            </div>
                        </div>
                        <div className="col-span-2 mt-1 relative ">
                            <textarea
                                id="desc"
                                name="desc"
                                rows="4"
                                placeholder="Message"
                                className={input_classes}
                            ></textarea>
                        </div>
                        <div className="sm:col-span-2">
                            <div className="flex items-start mt-4 mb-2 text-white text-base">
                                <label for="agreement" className="flex items-start mt-4 mb-2 text-white text-base">
                                    <input type="checkbox" required className="checkid leading-none w-4 mr-2" id="agreement" name="agreement" value="I agree to receive information about WebMobTech's news, products, & updates." />
                                    I agree to receive information about WebMobTech's news, products, & updates.
                                </label>
                            </div>
                        </div>
                        <div className="col-span-2 mt-1 relative ">
                            {recaptchaComponent}
                        </div>
                        <div className="col-span-2 flex justify-between items-center mt-5">
                            {/* <span className="inline-flex items-center">
                                <span
                                    className={`${nda ? "bg-white" : "bg-blue-300"
                                        } relative inline-block flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition ease-in-out duration-200 focus:outline-none focus:shadow-outline`}
                                    role="checkbox"
                                    aria-checked="false"
                                    aria-hidden="true"
                                    onClick={() => setNda(s => !s)}
                                    id="nda"
                                    name="nda"
                                >
                                    <span
                                        className={` ${nda ? "translate-x-5" : "translate-x-0"
                                            } inline-block h-5 w-5 rounded-full bg-blue-500 shadow transform transition ease-in-out duration-200`}
                                    ></span>
                                </span>
                                <span className="text-white text-lg font-medium pl-4">
                                    Send NDA
                                </span>
                            </span> */}
                            <span className="inline-flex items-center text-white font-medium">
                                Serving clients since 2010
                            </span>
                            <div >
                                {disabled ?
                                    <button
                                        type="submit"
                                        disabled
                                        className="text-shark-500 relative px-8 py-3 text-base leading-6 whitespace-nowrap font-medium bg-yellow-300 opacity-75 cursor-not-allowed rounded"
                                    >
                                        Let's talk
                                    </button> :
                                    <button
                                        disabled={!isRecaptcha ? true : false}
                                        type="submit"
                                        className={isRecaptcha ? "text-shark-500 w-full relative px-8 py-3 text-base leading-6 whitespace-nowrap font-medium bg-yellow-500 hover:bg-yellow-600 rounded" : "text-shark-500 relative px-8 py-3 text-base leading-6 whitespace-nowrap font-medium bg-yellow-300 opacity-75 cursor-not-allowed rounded"}
                                    >
                                        Let's talk
                                    </button>}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
const input_classes = `
input_custom_placeholder_white
rounded-md bg-transparent 
form-input border-white 
border-opacity-50 
focus:border-white 
text-white 
py-3 
mt-2 
px-4 
block 
w-full 
border 
border-solid 
transition ease-in-out duration-150
`

export default React.memo(NewContactForm)